<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
          <v-checkbox v-model="v2" label="V2"></v-checkbox>
        </v-col> -->
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>100.00000 Aktiva Lancar</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>110.00000 Kas & Setara Kas</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>111.00000 Kas</td>
              <td></td>
              <td>{{ formatPrice(neraca.account111) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>112.00000 Bank</td>
              <td></td>
              <td>{{ formatPrice(neraca.account112) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>113.00000 Deposito</td>
              <td></td>
              <td>{{ formatPrice(neraca.account113) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalKas) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>120.00000 Piutang Dagang</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account120) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>130.00000 Tagihan Lain-lain</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account130) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>140.00000 Piutang Karyawan</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account140) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>150.00000 Uang Muka Pembelian</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account150) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>160.00000 Persediaan</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>160.00001 Pers. Komponen</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1601) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>160.00002 Pers. WIP</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1602) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>160.00003 Pers. Finish Goods</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1603) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>160.00004 Pers. In-Transit</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1604) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalPersediaan) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>170.00000 Biaya Dibayar Dimuka</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>171.00000 Biaya Dibayar Dimuka RP</td>
              <td></td>
              <td>{{ formatPrice(neraca.account171) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>174.00000 Kasbon</td>
              <td></td>
              <td>{{ formatPrice(neraca.account174) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalBiayaDimuka) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>180.00000 Pajak Dibayar Dimuka</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>180.00001 Pajak Masukan</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1801) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>180.00002 Pajak PPH - 22</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1802) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>180.00003 Pajak PPH - 23</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1803) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>180.00004 Pajak PPH - 25</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1804) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>180.00005 Pajak PPH - 4 ayat (2)</td>
              <td></td>
              <td>{{ formatPrice(neraca.account1805) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalPajakDimuka) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>190.00000 Aktiva Lancar Lainnya</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account190) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalAktivaLancar) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>200.00000 Aktiva Tidak Lancar</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>210.00000 Aktiva Tetap</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00001 Tanah</td>
              <td></td>
              <td>{{ formatPrice(neraca.account2101) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00002 Bangunan</td>
              <td>{{ formatPrice(neraca.account2102) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>220.00002 A. Peny. Bangunan</td>
              <td>{{ formatPrice(neraca.account2202) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalBangunan) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00005 Komputer</td>
              <td>{{ formatPrice(neraca.account2105) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>220.00005 A. Peny. Komputer</td>
              <td>{{ formatPrice(neraca.account2205) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalKomputer) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00003 Peralatan Kantor</td>
              <td>{{ formatPrice(neraca.account2103) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>220.00003 A. Peny. Peralatan Kantor</td>
              <td>{{ formatPrice(neraca.account2203) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalPeralatan) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00004 Perabot Kantor</td>
              <td>{{ formatPrice(neraca.account2104) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>220.00004 A. Peny. Perabot Kantor</td>
              <td>{{ formatPrice(neraca.account2204) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalPerabotan) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>210.00006 Kendaraan</td>
              <td>{{ formatPrice(neraca.account2106) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>220.00006 A. Peny. Kendaraan</td>
              <td>{{ formatPrice(neraca.account2206) }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalKendaraan) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalAktivaTetap) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>230.00000 Investasi</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account230) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>299.00000 Aktiva Tidak Lancar Lainnya</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>299.00001 Bangunan Dalam Proses</td>
              <td></td>
              <td>{{ formatPrice(neraca.account2991) }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalAktivaTidakLancar) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalAktiva) }}</td>
            </tr>
            <tr>
              <td></td>
              <td>AKTIVA</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalAktiva) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>300.00000 Hutang Jangka Pendek</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>310.00000 Hutang Dagang</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>311.00000 Hutang Dagang RP</td>
              <td></td>
              <td>{{ formatPrice(neraca.account311) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>312.00000 Hutang Dagang SGD</td>
              <td></td>
              <td>{{ formatPrice(neraca.account312) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>313.00000 Hutang Dagang USD</td>
              <td></td>
              <td>{{ formatPrice(neraca.account313) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>314.00000 Hutang Dagang EUR</td>
              <td></td>
              <td>{{ formatPrice(neraca.account314) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalHutangDagang) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>330.00000 Uang Muka Penjualan</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account330) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>340.00000 Hutang Biaya</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account340) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>350.00000 Hutang Bank</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account350) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>360.00000 Hutang Non Bank</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account360) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>390.00000 Hutang Lain-lain</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account390) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>320.00000 Hutang Pajak</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00001 Hutang PPN Keluaran</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3201) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00002 Hutang Pajak PPH – 21</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3202) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00003 Hutang Pajak PPH – 23</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3203) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00004 Hutang Pajak PPH – 25</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3204) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00005 Hutang Pajak PPH – 29</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3205) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00006 Hutang Pajak PPH – 4 ayat (2)</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3206) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>320.00007 Hutang Pajak PPH – 26</td>
              <td></td>
              <td>{{ formatPrice(neraca.account3207) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalHutangPajak) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalHutangJangkaPendek) }}</td>
            </tr>
            <tr>
              <td>400.00000 Hutang Jangka Panjang</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account400) }}</td>
            </tr>
            <tr>
              <td>500.00000 Modal</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>510.00000 Modal Saham</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account510) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>520.00000 Akumulasi Laba Tahun Lalu</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account520) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>530.00000 Laba Tahun Berjalan</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.account530) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalModal) }}</td>
            </tr>
            <tr>
              <td></td>
              <td>PASIVA</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(neraca.totalPasiva) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-neraca",
  data() {
    return {
      menuStartDate: false,
      params: {
        date: moment().format("yyyy-MM-DD"),
      },
      v2: true,
    };
  },
  computed: {
    ...mapState("report", ["neraca"]),
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      if (this.v2) {
        await this.$store
          .dispatch("report/neracaV2", this.params)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("report/neraca", this.params)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      if (this.v2) {
        await this.$store
          .dispatch("report/neracaExcelV2", this.params)
          .then(response => {
            fileDownload(response.data, `neraca.xlsx`);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("report/neracaExcel", this.params)
          .then(response => {
            fileDownload(response.data, `neraca.xlsx`);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
